import * as Sentry from "@sentry/nuxt";

const SENTRY_DSN = "https://a54694e5d09da3e7a329ef7fd3dfb3a3@o4505477866913792.ingest.us.sentry.io/4507907934978048";

Sentry.init({
  dsn: SENTRY_DSN,

  environment: process.env.ENVIRONMENT || 'development',

  sampleRate: 0.01,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.01,
});
